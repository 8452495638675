//==========================================================================
// Abstrakts (ITCSS & BEM Method) Style coding
//==========================================================================

//======================================== CONFIGS
@import '_config/colours';
@import '_config/typography';
//======================================== UTILS
@import '_utils/breakpoints';
@import '_utils/text';
@import '_utils/spacing';
@import '_utils/cover';
@import '_utils/background-cover';
@import '_utils/clearfix';
@import '_utils/hidden-visible';
@import '_utils/index';
//======================================== BASE
@import 'base/reset';
@import 'base/html-body';
@import 'base/typography';
@import 'base/icon';
@import 'base/inputs';
@import 'base/button';
//======================================== LAYOUT
@import 'layout/grid';
// Grid
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/footer';
//======================================== MODULES
@import 'modules/hero';
@import 'modules/cookie';
@import 'modules/pagination';
@import 'modules/hamburger';
@import 'modules/cta';
@import 'modules/block';
@import 'modules/tile';
@import 'modules/slider';
//======================================== VENDOR
@import '_vendor/flickty';
//======================================== PRINT
@import '_utils/print';


.takeover {
  position: relative;
  min-height: 100vh;
  min-width:  100%;

  p {
    margin-bottom: 50px;
  }

  h1 {
    position: relative;
    padding-top: 50px;
    margin-bottom: 50px;
    &::before {
      content:"";
      position: absolute;
      top:0;
      left:0;
      height:2px;
      width: 70px;
      background-color: white;
    }
  }

  .socials {
    position: relative;
    z-index: 2;
    padding: 40px gutter(1);

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
      display: inline-block;
      margin-right: 20px;
    }
  }

  @include breakpoint(small) {

    .socials {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 40px;

      li {
        display: block;
        margin-right: 0;
        margin-top: 20px;
      }

      &::before {
        content:"";
        position: absolute;
        bottom:100%;
        left:50%;
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
        background-color: white;
      }

      a {
        display: inline-block;
        transition: opacity .3s, transform .3s;

        &:hover {
          transform: scale(1.4);
          opacity: .4;
        }
      }
    }

    h1 {
      margin-top: 60px;
      padding-top: 70px;
      margin-bottom: 70px;
    }
  }

  &--404 {
    background-color: black;
    background-image: url('/dist/img/icons/bkg-404.svg');
    background-repeat: no-repeat;
    background-position: right -30px bottom 10%;
    background-size: 100%;

    &::before {
      content:"";
      @include cover();
      background-color: rgba(0,0,0,0.5);
    }

    .wrapper {
      position: relative;
    }

    @include breakpoint(small) {
      background-size: 70%;
      background-position: right 100px bottom 10%;
    }

    @include breakpoint(medium) {
      background-size: 70%;
      background-position: right 100px bottom 10%;

      &::before {
        background-color: rgba(0, 0, 0, 0);
      }
    }
    @include breakpoint(large) {
      background-size: 60%;
      background-position: right 100px bottom 10%;

      &::before {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
}
