// ==========================================================================
// #HEADER
// ==========================================================================
#header {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;


  .logo {
    position: relative;
    z-index: 10;
    padding: 35px gutter(1);
    display: inline-block;
  }

  .navigation-burger {
    position: fixed;
    top: 0;
    right: 0;
    padding: 38px 20px;
    background-color: black;
    transition: background-color .3s;
  }


  .navigation {
    visibility: hidden;
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 7;
    height: 100vh;
    padding-top: 150px;
    transition: visibility .3s, opacity .3s;

    .ill__hold {
      display: none;
    }

    .nav__main {
      a {
        font-family: $heading-font;
        font-weight: bold;
        font-size: 7rem;
        text-decoration: none;
        color: white;
      }
    }

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0 gutter(1);
      margin: 0 auto;
    }

    .socials {
      position: absolute;
      right: 25px;
      bottom: 125px;

      ul {
        margin: 0;
      }


      a {
        pointer-events: auto;
      }
    }
  }


  .menu-bars {
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #292929;
    transition: transform .3s ease-out, visibility .3s;
    transform: translateX(100%);

    &::before {
      content: "";
      @include cover;
      background-color: black;
      transform: translateX(100%);
      transition: transform .3s ease-out .4s;
    }


    //&::before, &::after, .mid {
    //  position: absolute;
    //  height: 34%;
    //
    //  width: 100%;
    //
    //}
    //
    //&::before, &::after {
    //  content: "";
    //  left: 0;
    //  transform: translateX(100%);
    //}
    //
    //.mid {
    //  top: 33.333%;
    //  right: 0;
    //
    //}
    //
    //&::before {
    //  top: 0;
    //}
    //
    //&::after {
    //  top: 66.666%;
    //}
  }


  .in-ele {
    opacity: 0;
    transform: translateY(50%);
    pointer-events: none;
    transition: transform .7s, opacity .6s;
    transition-timing-function: ease-in;

    &.is-opened {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
      transition-timing-function: ease;
    }
  }

  &.open {

    .in-ele:nth-of-type(1) {
      transition-delay: .65s;
    }

    .in-ele:nth-of-type(2) {
      transition-delay: 0.8s;
    }

    .in-ele:nth-of-type(3) {
      transition-delay: .95s;
    }

    .in-ele:nth-of-type(4) {
      transition-delay: 1.1s;
    }

    .in-ele:nth-of-type(5) {
      transition-delay: 1.25s;
    }

    .in-ele:nth-of-type(6) {
      transition-delay: 1.4s;
    }

    .logo {
      position: fixed;
      top: 0;
      left: 0;

      path, polygon {
        fill: white;
      }
    }


    .navigation-burger {
      background-color: black;
    }

    .menu-bars {
      visibility: visible;
      transform: translateX(0);
      transition: transform .3s, visibility .3s;

      &::before {
        transform: translateX(0);
      }
    }

    .navigation {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header--flip {
  .logo {
    path, polygon {
      fill: white;
    }
  }
}


// Only mobile
@include breakpoint-max(medium) {
  #header {
    right: 0;
    width: 100%;
  }
}

@include breakpoint(small) {
  #header {
    .navigation-burger {
      width: 70px;
      padding: 38px 22px;
    }

    .navigation {
      align-items: flex-start;


      .nav__main {

        a {
          display: block;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            height: 2px;
            background-color: white;
            transition: width .3s;
            width: 0;
          }

          span {
            display: block;
            transition: transform .3s, opacity .3s;
          }

          &:hover {
            span {
              transform: translateX(35px);
              opacity: .5;
            }

            &::before {
              width: 50px;
            }
          }
        }
      }

      .ill__hold {
        display: block;
        width: 40%;
        position: relative;


        .ill {
          padding: 40px;
          position: relative;
          display: inline-block;

          img {
            position: relative;
          }

          &::before {
            content: "";
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 50%;
            background-image: linear-gradient(180deg, #000000 0%, #292929 100%);
            transition: opacity .3s;
            transition-delay: .65s;
          }
        }


      }

      .inner {
        width: 60%;
        height: auto;
        padding-left: 80px;
      }

      .socials {
        bottom: 50px;
        right: 25px;


        &::before {
          content: "";
          position: absolute;
          bottom: 140px;
          left: 50%;
          transform: translateX(-50%);
          height: 165px;
          width: 1px;
          background-color: #ffffff;
        }
      }
    }

    &.open {
      .navigation .ill__hold .ill::before {
        opacity: 1;
      }
    }
  }
}

@include breakpoint(medium) {
  #header {
    .navigation-burger {
      width: 130px;
      padding: 38px 52px;
    }

    .logo {
      padding: 35px 40px;
    }

    .navigation {

      .ill__hold {
        width: 45%;
      }

      .inner {
        width: 55%;
        padding-left: 80px;
      }

      .socials {
        bottom: 30px;
        right: 52px;

        &::before {

          //bottom: 185px;
        }
      }
    }

  }
}

@include breakpoint(large) {
  #header .navigation .nav__main a span {
    line-height: 1.3;
  }
}