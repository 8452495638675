/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/

.g {
  width: 100%;
  lost-flex-container: row;

  > * {
    width: 100%;
  }
}

.g--ng {
  > * {
    width: 50%;
    flex-basis: 50%;
  }
}

.g__1 {
}

/*
  Footer grid modification
*/
.g--footer {
}

.g__1-2 {
  lost-column: 1/2;
}

.g__1-3 {
  lost-column: 1/3;
}

.g__2-3 {
  lost-column: 2/3;
}

.g--img {

  .g__1-3 {
    lost-column: 1/3 3 20px;
  }

  .g__2-3 {
    lost-column: 2/3 3 20px;
  }

}


.g__afe {
  align-items: flex-end;
}

@include breakpoint(small) {

  .gs__1-2 {
    lost-column: 1/2;
  }


  .gs__4-10 {
    lost-column: 4/10;
  }
  .gs__6-10 {
    lost-column: 6/10;
  }
}

@include breakpoint(medium) {

  .gm__ac {
    align-items: center;
  }
  .gm__jcb {
    justify-content: space-between;
  }

  .gm__1-1 {
    lost-column: 1/1;
  }
  .gm__1-2 {
    lost-column: 1/2;
  }

  .gm__1-3 {
    lost-column: 1/3;
  }

  .gm__2-3 {
    lost-column: 2/3;
  }

  .gm__3-10 {
    lost-column: 3/10;
  }
  .gm__4-10 {
    lost-column: 4/10;
  }
  .gm__6-10 {
    lost-column: 6/10;
  }
  .gm__7-10 {
    lost-column: 7/10;
  }

  .gm__2-12 {
    lost-column: 2/12;
  }
  .gm__3-12 {
    lost-column: 3/12;
  }
  .gm__4-12 {
    lost-column: 4/12;
  }
  .gm__5-12 {
    lost-column: 5/12;
  }
  .gm__6-12 {
    lost-column: 6/12;
  }
  .gm__8-12 {
    lost-column: 8/12;
  }
  .gm__9-12 {
    lost-column: 9/12;
  }
}

@include breakpoint(large) {

  .g--gutter {
    .gl__1-3 {
      lost-column: 1/3 3 gutter(1.5);
    }
  }

  .gl__1-3 {
    lost-column: 1/3;
  }


  .gl__6-10 {
    lost-column: 6/10;
  }


  .gl__2_5-12 {
    lost-column: 2.5/12;
  }
  .gl__6_5-12 {
    lost-column: 6.5/12;
  }
}
