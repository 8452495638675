//=====================================
// #HELPERS-TEXT
//=====================================

.tt-u {
    text-transform: uppercase;
}

.bold {
    font-weight: 800;
}

.normal {
    font-weight: 400;
}

em, .italic {
    font-style: italic;
}

.under {
    text-decoration: underline;
}

.ta-c {
    text-align: center;
    > * {
        text-align: center;
    }
}

.ta-l {
    text-align: left;
}

.ta-r {
    text-align: right;
}

.td-n {
    text-decoration: none;
}

.a11y {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
}