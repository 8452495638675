// ==========================================================================
// #FOOTER
// ==========================================================================
#footer {
  padding-top: 5vh;
  background-color: #292929;

  .copyright {
    padding-top: 20px;
    background-color: #000000;
  }

  p, a {
    color: white;
    transition: opacity .3s;
  }

  a {
    &:hover {
      opacity: .5;
    }
  }

  .contact-location {
    a {
      text-decoration: underline;
    }
  }

  .page-links {
    a {
      font-weight: bold;
      text-decoration: none;
    }
  }

  .footer__socials {
    margin-bottom: 20px;
  }
}

@include breakpoint-max(small) {

  #footer {
    .copyright {

      .g {
        display: flex;
        justify-content: space-between;

        > * {
          flex: 1;
        }
      }

    }
  }
}

@include breakpoint(small) {
  #footer {

    .footer__socials {
      margin-bottom: 0;
    }

    .middle-col {
      .big {
        padding-right: 235px;
      }
    }
  }
}

@include breakpoint(medium) {
  #footer {
    .middle-col {
      border-right: 1px solid #8A8A8A;

      .big {
        padding-right: 20px;
      }
    }


  }
}

@include breakpoint(large) {
  #footer {

    .middle-col {
      .big {
        padding-right: 75px;
      }
    }

    .contact-location {
      padding-left: 50px;
    }
  }
}