.cta {
  position: relative;
}

.cta-single {
  background-color: black;


  a {
    transition: opacity .3s;

    &:hover {
      opacity: .5;
    }

  }


  .icon__hold {
    padding-top: 60px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 40px;
      top: 0;
      left: 50%;
      background-color: white;
    }
  }
}

.cta-block {
  overflow: hidden;
  position: relative;
  background-color: pink;

  .hold {
    position: relative;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 100vw;
      background-color: black;
    }


    .inner {
      position: relative;
    }
  }
}