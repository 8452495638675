//=====================================
// #FORM
//=====================================
label {
  font-size: $p-size--small;
  font-weight: bold;
  opacity: .5;
}

input {
  border: none;
  width: 100%;
  border-bottom: solid 1px black;
  background-color: transparent;
  border-radius: 0;
  font-size: $p-size;
  font-weight: bold;
  line-height: 1.3;
  padding: 15px 0;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  transition: background .3s, border .5s;


  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
  }

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:placeholder-shown) {
    background-color: #fbfbfb;
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;
  }
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0;
}

.input--hold {
  margin-bottom: 30px;
}

textarea {
  border: none;
  border-bottom: solid 1px black;
  border-radius: 0;
  font-size: $p-size;
  font-family: $body-font;
  font-weight: bold;
  line-height: 1.3;
  padding: 15px 0;
  width: 100%;
  outline: none;
  transition: background .3s, border .5s;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
  }

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

select {
  border: none;
  border-bottom: solid 1px black;
  border-radius: 0;
  font-size: $p-size;
  font-family: $body-font;
  font-weight: bold;
  line-height: 1.3;
  width: 100%;
  padding: 15px 40px 15px 0;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input--hold-file input:disabled {
  border: 0;
  background-color: #fff;
  width: calc(100% - 170px);
}

button[disabled], html input[disabled] {
  cursor: default;
}

.input--hold-file {
  border: none;
  border-bottom: solid 1px black;

  input:disabled {
    border: 0;
    background-color: white;
    width: calc(100% - 170px);
  }

  .fileupload {
    position: relative;
    float: right;
    opacity: 1 !important;

    .btn {
      color: white !important;
      background-color: black;
      border-color: black;
      padding: 5px 30px;
      margin-top: 4px;
      margin-right: 4px;

      &::before, &::after {
        content: none;
      }
    }
  }

  .uploadhide {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

//=====================================
// #FORM-TYPES
//=====================================
.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;

  label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px;

    span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e;
    }
  }

  input, textarea, select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333;
  }

  input {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }

    &[type=file] {
      border: 0;
      padding-top: 0;
      width: 100%;
      display: flex;
    }
  }

  textarea {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      font-size: 14px;
    }
  }

  select {
    width: 100%;
    font-size: 18px;
    transition: border .5s;
  }

  &.active--input select {
    border-bottom: 2px solid white;

    ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
}

#firstName {
  display: none;
}

// Honey pot

.checkbox {
  display: flex;

  label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.3;
    opacity: 1;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px;

    &::before, &::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: 1px solid black;
      left: 0;
      background-color: #d9d9d9;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px;
    }

    &::after {
      color: white;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }

  input[type="checkbox"] {

    + label::after {
      content: none;
    }

    &:checked + label {
      &:before {
        background-color: black;
        border-color: black;
      }

      &::after {
        content: "";
      }
    }

    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }

    &:disabled ~ label {
      &:before, &:after {
        opacity: .5
      }
    }
  }

  &.error {
    label::before, label::after {
      animation: shake .5s ease-in-out forwards;
      //transform: scale(1.2);
    }
  }
}

//=====================================
// #FORM-COLORS
//=====================================
.input--success {
  border-color: palette(green, success);
}

.input--error {
  border-color: palette(red, error);
}

.form-hold {
  position: relative;
}

.form-complete {
  overflow: hidden;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -100%);
  transition: all .5s ease .5s;

  .inner {
    position: relative;
  }
}

.form-hold--complete {
  .form-container {
    opacity: 0;
    visibility: hidden;
  }

  .form-complete {
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
  }
}

