//=====================================
// #HERO
//=====================================
.hero {
  position: relative;

}

.hero-home {
  overflow: hidden;
  background-color: black;

  .bar {
    display: none;
  }

  .inner {
    min-height: 100vh;
    padding: 100px 20px 40px;

    h1 {
      font-weight: 400;
    }

    strong {
      text-decoration: underline;
      text-underline-offset: 14px;
    }

    #jumper {
      display: none;
    }
  }


  @include breakpoint(small) {
    .inner {
      position: relative;
      padding: 150px 20px 40px;

      background-image: url(/dist/img/icons/logo-bkg-black.svg);
      background-repeat: no-repeat;
      background-position: right 30px center;
      background-size: contain;

      p {
        max-width: 570px;
      }

      #jumper {
        display: block;
        position: absolute;
        left: 20px;
        bottom: 20px;
      }
    }
  }

  @include breakpoint(medium) {
    position: relative;
    padding-right: 130px;

    .inner {
      padding: 150px 50px 40px 100px;

      #jumper {
        left: 100px;
        bottom: 30px;
      }
    }



    .bar {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 130px;

      .top {
        position: absolute;
        top: 0;
        background-color: black;
        height: 50%;
        width: 100%;

        a {
          display: block;
          position: absolute;
          left: 50%;
          bottom: 50px;
          color: white;
          font-size: 1.8rem;
          white-space: nowrap;
          transform: rotate(-90deg);
          transform-origin: left;
          padding-left: 30px;
          transition: opacity .3s;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transform-origin: center;
            height: 10px;
            width: 10px;
            background-color: white;
            border-radius: 50%;
            transition: transform .3s;
          }

          &:hover {
            opacity: .5;
          }
        }
      }


      .socials {
        background-color: black;
        position: absolute;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;

        ul {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
          padding: 80px 40px 20px;

          &::before {
            content: "";
            position: absolute;
            bottom: 140px;
            left: 50%;
            transform: translateX(-50%);
            height: 165px;
            width: 1px;
            background-color: white;
          }
        }

        li {
          display: block;
          margin-right: 0;
          margin-top: 20px;
        }


        a {
          display: inline-block;
          transition: opacity .3s, transform .3s;

          &:hover {
            transform: scale(1.4);
            opacity: .4;
          }
        }
      }

    }
  }

  @include breakpoint(large) {


    .inner {
      padding: 180px 50px 80px 120px;

      #jumper {
        left: 120px;
        bottom: 30px;
      }
    }

  }
}

.hero--simple {
  overflow: hidden;
  position: relative;

  .wrapper {
    padding-top: 130px;
    padding-bottom: 30px;
    position: relative;

    .bkg-svg {
      display: none;
      position: absolute;
      bottom: -1px;
      right: 0;
    }
  }

  .bar {
    display: none;
  }


  .h1 {
    position: relative;
    padding-top: 40px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 70px;
      background-color: black;
    }
  }
}

.hero--dark {
  background-color: black;

  .h1, p {
    color: white;
  }

  .h1::before {
    background-color: white;
  }
}

@include breakpoint(small) {


  .hero--bkg img {
    padding-right: 70px;
  }

  .hero--simple {


    .bar {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 70px;
      background-color: #292929;

      a {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 50px;
        color: white;
        font-size: 1.8rem;
        white-space: nowrap;
        transform: rotate(-90deg);
        transform-origin: left;
        padding-left: 30px;
        transition: opacity .3s;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          transform-origin: center;
          height: 10px;
          width: 10px;
          background-color: white;
          border-radius: 50%;
          transition: transform .3s;
        }

        &:hover {
          opacity: .5;
        }
      }
    }

    .wrapper {
      padding-right: 90px;
      padding-top: 180px;
      padding-bottom: 100px;

      .bkg-svg {
        right: 20px;
        max-width: 410px;
        display: block;

        img {
          max-height: 370px;
        }
      }
    }
  }
}

@include breakpoint(medium) {
  .hero--bkg img {
    padding-right: 125px;
  }
  .hero--simple {
    .bar {
      width: 130px;
    }

    .wrapper {
      padding-right: 150px;
      padding-top: 200px;
      padding-bottom: 120px;

      .bkg-svg {
        right: 100px;
        max-width: 500px;

        img {
          max-height: 400px;
        }
      }
    }
  }
}

@include breakpoint(large) {
  .hero--simple {

    .wrapper {

      .bkg-svg {
        right: 180px;
      }
    }
  }
}
