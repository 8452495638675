.tile-work {
  display: block;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20);
  margin-bottom: 35px;

  .img {
    font-size: 0;
    line-height: 1;
    transition: opacity .3s;
  }

  .bold {
    text-transform: uppercase;
    font-size: 4rem;
  }

  .text {
    padding: 20px;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .square {
    z-index: 2;
    position: absolute;
    right: 0;
    background-color: black;
    padding: 15px;
    height: 60px;
    width: 60px;

    .view {
      opacity: 0;
    }

    .plus, .view {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: opacity .3s;
    }
  }

  &:hover {

    .img {
      opacity: .5;
    }

    .view {
      opacity: 1;
    }

    .plus {
      opacity: 0;
    }
  }
}

@include breakpoint(small) {
  .tile-work {
    box-shadow: none;

    .bold {
      font-size: 3rem;
    }


    &--small {

      .text {
        position: relative;
        z-index: 2;
        background-color: white;
        margin-top: -50px;
        width: 80%;
      }
    }

    &--feature {
      margin-bottom: 80px;

      .bold {
        padding-right: 100px;
      }

      .text {
        padding: 30px 0;
      }
    }
  }
}

@include breakpoint(large) {
  .tile-work {

    &--small {

      .text {
        padding: 40px;
        margin-top: -90px;
        width: 65%;
      }
    }
  }
}