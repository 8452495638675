.navigation-burger {
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 5px 0;
  float: right;
}


.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 22px;
  margin: 0;
  position: relative;
  width: 25px;
  outline: none;

  span {
    height: 0;
    border-bottom: 2px solid white;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden;
  }

  &:hover {
    span {
      width: 100%;
    }
  }

  &:after, &:before {
    transform: translateY(-50%);
    position: absolute;
    border-bottom: 2px solid white;
    content: '';
    height: 0;
    width: 100%;
    right: 0;
    transition: all .3s ease-out;
  }

  &:before {
    top: 0;
  }

  &:after {
    top: 100%;
  }

  &.open {
    &:before, span {
      top: 50%;
      transform: rotate(45deg);
    }

    &:after {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}