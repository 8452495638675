//=====================================
// #HEADINGS
//=====================================
$h1-size: 8rem;
$h2-size: 4.8rem;
$h3-size: 2.8rem;
$h4-size: 2.4rem;

$p-size: 1.8rem;
$p-size--small: 1.6rem;
$p-size--big: 2.2rem;

$heading-font: rift, sans-serif;
$body-font: urw-din, sans-serif;
