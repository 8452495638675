//=====================================
// #BUTTON
//=====================================
.btn {
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  font-family: $heading-font;
  color: black !important;
  background: transparent;
  padding: 20px 80px;
  border: 2px solid black;
  text-transform: uppercase;
  font-size: 2.4rem;
  font-weight: bold;
  min-width: 160px;
  overflow: hidden;
  user-select: none;

  span {
    display: block;
    z-index: 5;
    position: relative;
    transition: color 350ms ease, transform 350ms ease;
  }

  &:hover {

    span {
      color: white !important;
      transform: scale(1.2);
    }
  }

  &::after, &::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: black;
    z-index: -1;
  }

  &::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
  }

  &:hover:before {
    transform: translateX(0);
    transition: transform 350ms ease;
  }

  &::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 350ms ease;
  }

  &:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 350ms 360ms ease;
  }
}

.btn--full { width: 100%; }

.btn--r { float: right; }

//=====================================
// #BUTTON-SIZES
//=====================================
.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}

//=====================================
// #BUTTON-COLORS
//=====================================
.btn--white {
  color: white !important;
  border: 2px solid white;

  &:hover {
    span {
      color: #000 !important;
    }
  }

  &::after, &::before {
    background: white;
  }
}

.btn--white-fill {
  border-color: white;
  background-color: white;
  color: black;
}