//=====================================
// #HEADINGS
//=====================================
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-family: $heading-font;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 25px;
}

h1, .h1 {
  font-size: 7rem;
  @include breakpoint(small) {
    font-size: $h1-size;
  }
}

h2, .h2 {
  font-size: 4rem;
  @include breakpoint(small) {
    font-size: $h2-size;
  }
}

h3, .h3 {
  font-size: $h3-size;
}

h4, .h4 {
  font-size: $h4-size;
}

.thin {
  font-weight: 300;
}

//=====================================
// #TEXT
//=====================================
p, .p {
  font-family: $body-font;
  font-size: $p-size;
  line-height: 1.7;
  margin-bottom: 20px;
  font-weight: 300;
}

p a, li a {
  font-weight: bold;
  text-decoration: underline;
  transition: opacity .3s;

  &:hover {
    opacity: .5;
  }
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}

a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: palette(black);
  transition: color .3s;

  &:hover {

  }
}

.link--icon {
  span {
    margin-left: 10px;
  }
}

//=====================================
// #LISTS
//=====================================
ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.ul--unstyled, &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }
  }

  &.ul--row {
    li {
      display: inline-block;
      vertical-align: center;
    }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: $p-size;
  line-height: 1.5;
  font-weight: 300;
  color: palette(black);
  padding-bottom: 15px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

//=====================================
// #COLOURS/LINKS
//=====================================
.color--white {
  color: white;

  > * {
    color: white;
  }
}

.img__split {
  .img {
    background: rgb(235, 235, 235);
    background: linear-gradient(180deg, rgba(235, 235, 235, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
}


.body {
  //p a {
  //  border-bottom: 2px solid black;
  //  font-weight: bold;
  //}

  @include breakpoint(medium) {
    //ul {
    //  &::after {
    //    content: "";
    //    display: table;
    //    clear: both;
    //  }
    //
    //  li {
    //    width: 50%;
    //    float: left;
    //  }
    //}
  }

}

.footer_img {
  font-size: 0;
}

.link-back {
  font-weight: bold;
  text-decoration: underline;
}