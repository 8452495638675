.intro {


  .inner {
    position: relative;
    padding-top: 40px;

    &::before {
      content: "";
      height: 2px;
      width: 70px;
      background-color: black;
      top: 0;
      left: 0;
      position: absolute;
    }
  }


}

.process {
  position: relative;
  margin-bottom: 65px;

  .counter {
    padding-left: gutter(1);
    font-family: $heading-font;
    font-weight: bold;
    font-size: 8rem;
    line-height: 1;
  }

  .inner {
    padding-top: 45px;
  }

  .inner {
    border-top: 1px solid black;
  }

  .imgs {
    position: relative;
    padding-left: 60px;
    padding-bottom: 60px;

    .img-icon {
      position: absolute;
      right: 0;
      bottom: -15px;


      .icon-block {
        &, svg {
          width: 70px;
          height: 70px;
        }
      }
    }

    .small-img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
    }
  }

  img {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20);
  }
}

.img-grid {


  .img__hold {
    position: relative;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20);
    height: 100px;
    background-color: white;
    margin-bottom: 20px;
  }
}

.contact-links {

  .bold {
    opacity: .5;
  }

  a {
    text-decoration: underline;
    font-weight: 400;
  }
}

.lightswitch__hold {
  padding-top: 25px;
  text-align: center;
  position: relative;
  z-index: 8;

  button {
    outline: none;
    display: inline-block;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: transform .3s;
    transform-origin: center;
  }
}

.bkg--flip {

  .lightswitch__hold {

    button {
      transform: rotate(180deg);

      svg path {
        fill: white;
      }

      svg circle {
        stroke: white;
      }
    }
  }
}

@include breakpoint(500px) {
  .img-grid {
    .img__hold {
      height: 170px;
    }
  }
}

@include breakpoint(small) {
  .img-grid {
    .img__hold {
      height: 230px;
    }
  }

  .process {
    margin-bottom: 170px;

    .counter {
      position: absolute;
      top: 150px;
      left: 0;
      font-size: 35rem;
      transform: translateX(-30%);
    }

    .imgs {
      padding-left: 0;
      padding-bottom: 0;

      .img-icon {
        position: absolute;
        right: initial;
        bottom: initial;
        top: 0;
        left: 0;
        transform: translateX(-100px) translateY(20px);


        .icon-block {
          &, svg {
            width: 70px;
            height: 70px;
          }
        }
      }

      .small-img {
        width: 70%;
        transform: translateX(-100px) translateY(90px);
      }
    }

  }
}

@include breakpoint(medium) {

  .img-grid {
    .img__hold {
      height: 300px;
    }
  }

  .process {
    margin-bottom: 190px;

    .imgs {

      .img-icon {
        transform: translateX(-200px) translateY(80px);

        .icon-block {
          &, svg {
            width: 100px;
            height: 100px;
          }
        }
      }

      .small-img {
        width: 70%;
        transform: translateX(-200px) translateY(110px);
      }
    }

    ul {
      &::after {
        content: "";
        display: table;
        clear: both;
      }

      li {
        width: 50%;
        float: left;
      }
    }
  }
}

@include breakpoint(large) {


  .img-grid {
    .img__hold {
      height: 400px;
    }
  }

  .process {

    .imgs {

      .img-icon {
        transform: translateX(-200px) translateY(150px);

        .icon-block {
          &, svg {
            width: 100px;
            height: 100px;
          }
        }
      }

      .small-img {
        width: 60%;
        transform: translateX(-200px) translateY(110px);
      }
    }
  }
}