//=====================================
// #COOKIE
//=====================================
#cookie-message {
  background-color: black;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;

  p, a {
    color: white;
  }

  .cookie-button {
    text-align: center;
  }

  .wrapper {
    padding: gutter(2) gutter(1);
  }
}

@include breakpoint(medium) {
  #cookie-message {

    p {
      margin-bottom: 0;
    }

    .wrapper {
      padding: 40px;
    }
  }
}