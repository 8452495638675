.slider {

  .item {
    width: 100%;

    &.is-selected {
      z-index: 2;
    }
  }
}

.slider-quote__hold {
  overflow: hidden;
}

.slider-quote {

  .flickity-viewport {
    overflow: visible !important;
  }


  .item {
    position: relative;
  }

  .img {
    position: absolute;
    text-align: right;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;


    img, svg {
      opacity: .1;

      max-height: 200px;
    }
  }
}

.slider__controls {
  button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }

  .quote-prev {
    margin-right: 10px;
  }
}


.slider-hero {
  overflow: hidden;

  .bar {
    display: none;
  }

  .slider-bar {
    display: none;
  }

  .text {
    background-color: #292929;
    color: white;
    padding-top: 150px;
    padding-left: gutter(1);
    padding-right: gutter(1);
    padding-bottom: 35px;

    .h1 {
      font-weight: 300;
      position: relative;
      font-size: 4rem;
      padding-top: 30px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 2px;
        background-color: white;
      }
    }
  }

  .imgs {
    background-color: white;
    background-image: url('/dist/img/icons/logo-bkg.svg');
    background-repeat: no-repeat;
    background-position: right 30px center;
    background-size: contain;
    transition: background-color .3s;

    .item {
      padding: 40px 20px 10px;

      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;

      img {
        max-height: 300px;
      }
    }
  }

  .counter {
    padding: 0 20px 15px;


    .number {
      text-align: right;
      padding-bottom: 10px;
      font-size: 6rem;
      font-weight: bold;
      font-family: $heading-font;

      .small-text {
        font-size: 3rem;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      background-color: rgba(0, 0, 0, .2);
      overflow: hidden;
    }

    li {
      flex: 1;
      height: 2px;
      padding: 0;
      background-color: transparent;
      transition: background-color .3s;

      &.slider-bar-active {
        background-color: black;
      }
    }
  }

  .controls {
    background-color: #E2E2E2;
    position: relative;
    min-height: 121px;
    padding-right: 70px;

    .arrow {
      opacity: .5;
      transform-origin: center;
      transition: opacity .3s, transform .3s;
    }

    button {
      outline: none;
      cursor: pointer;

      &:hover {
        .arrow {
          opacity: 1;
          transform: scale(1.4);
        }
      }
    }

    .item {
      padding: 40px 20px 0;
      font-family: $heading-font;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 3rem;
    }


    .slider__controls {
      position: absolute;
      right: 0;
      top: 0;
    }

    .home-hero-prev {
      margin-bottom: 1px;
    }
  }
}


.bkg--flip .slider-hero {
  .imgs {
    background-color: black;
    background-image: url(/dist/img/icons/logo-bkg-black.svg);
  }

  .controls {

    svg path {
      fill: white;
    }

    .arrow {
      stroke: black !important;
    }
  }

  .counter {

    .number {
      color: white;
    }

    ul {
      background-color: rgba(255, 255, 255, .2);
    }

    li {
      &.slider-bar-active {
        background-color: white;
      }
    }
  }
}

@include breakpoint(medium) {
  .slider-quote {

    .img {
      width: 65%;


      img, svg {
        opacity: .1;
        max-height: 300px;
      }
    }
  }


  .slider-hero {
    position: relative;
    padding-right: 130px;

    .counter {
      position: absolute;
      right: 0;
      bottom: 44px;
      width: 50%;
      padding-right: 180px;
    }

    .bar {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 130px;

      .top {
        position: absolute;
        top: 0;
        background-color: #292929;
        height: 50%;
        width: 100%;

        a {
          display: block;
          position: absolute;
          left: 50%;
          bottom: 50px;
          color: white;
          font-size: 1.8rem;
          white-space: nowrap;
          transform: rotate(-90deg);
          transform-origin: left;
          padding-left: 30px;
          transition: opacity .3s;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transform-origin: center;
            height: 10px;
            width: 10px;
            background-color: white;
            border-radius: 50%;
            transition: transform .3s;
          }

          &:hover {
            opacity: .5;
          }
        }
      }


      .socials {
        background-color: white;
        position: absolute;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;

        ul {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
          padding: 80px 40px 20px;

          &::before {
            content: "";
            position: absolute;
            bottom: 78px;
            left: 50%;
            transform: translateX(-50%);
            height: 200px;
            width: 1px;
            background-color: #000000;
          }
        }

        li {
          display: block;
          margin-right: 0;
          margin-top: 20px;
        }


        a {
          display: inline-block;
          transition: opacity .3s, transform .3s;

          &:hover {
            transform: scale(1.4);
            opacity: .4;
          }
        }
      }

    }

    .text {
      background: rgba(41, 41, 41, .9);
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      bottom: 0;
      width: 400px;


      padding-top: 150px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 35px;

      .h1 {
        padding-top: 60px;
        font-size: 6rem;
      }
    }


    .imgs {


      .item {
        padding: 50px 20px 180px;

        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;

        img {
          max-height: 600px;
        }
      }
    }

    .controls {
      position: absolute;
      z-index: 3;
      left: 0;
      bottom: 0;
      width: 460px;

      .item {
        padding: 40px 40px 0;
      }
    }
  }
}


@include breakpoint(large) {
  .slider-hero {
    .text {
      width: 500px;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 135px;

      .h1 {
        padding-top: 80px;
      }
    }

    .controls {
      width: 560px;
    }
  }
}